import { Badge } from '@sravni/react-design-system';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';

export const getCardBadge = (
  offer: IClientMicrocreditListItem,
  hasUtmLabel: boolean,
  styles?: Record<string, string>,
) => {
  const { isPartner, labelText } = offer?.advertising || {};
  const [advertisingLabel] = labelText?.split(';') || [];
  const seoLabel = offer?.seoLabel || '';

  const shouldShowSeoLabel = Boolean(seoLabel) && !hasUtmLabel;
  const shouldShowAdvertisingLabel = Boolean(advertisingLabel);

  if (isPartner) {
    return <Badge color="orange" variant="primary" text="Mы рекомендуем" className={styles?.badge} />;
  }
  if (shouldShowAdvertisingLabel) {
    return <Badge color="blue" variant="primary" text={advertisingLabel} className={styles?.badge} />;
  }
  if (shouldShowSeoLabel) {
    return <Badge color="gray" variant="secondary" text={seoLabel} className={styles?.badge} />;
  }

  return null;
};
