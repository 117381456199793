import { isDefined } from '@sravni/react-utils';

import type { List } from '@src/@types/microcredits';
import { getRateRangeString } from '@src/helpers/common';

export const getOfferRate = (rates: List.IMicrocreditDetails[]) => {
  let isWeHaveRange = false;

  const range = rates.reduce(
    (acc, { rate }) => {
      acc.from = Math.min(acc.from, rate.from);
      acc.to = Math.max(acc.to, rate.to);

      if (!isWeHaveRange && (isDefined(rate.from) || isDefined(rate.to))) {
        isWeHaveRange = true;
      }

      return acc;
    },
    {
      from: 0,
      to: 0,
    },
  );

  if (!isWeHaveRange) {
    return '—';
  }

  return `${getRateRangeString(range, true, false, true)}%`;
};
