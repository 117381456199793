import cn from 'classnames';
import { useRouter } from 'next/router';
import type { ReactNode, SyntheticEvent } from 'react';
import React, { memo } from 'react';

import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';
import { Button } from '@sravni/react-design-system/lib/Button';

import type { AffSub4 } from '@src/@types/microcredits';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { MONETIZATION_INTERNAL } from '@src/helpers/advertising';
import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';
import { sendAdvertisingButtonClickEvent } from '@src/helpers/analyticsEvents';
import { open } from '@src/helpers/navigationHelper';
import { usePixel } from '@src/hooks/usePixel';

import styles from './styles.module.scss';

interface IProps extends ButtonProps {
  className?: string;
  advSub?: string;
  source?: string;
  analytics: IAnalyticsItem;
  visibleLabel?: ReactNode;
  productLinkHref?: string;
  affSub4?: AffSub4;
  link?: string;

  onClick?: () => void;
}

export const AdvertisingButton: React.FunctionComponent<IProps> = memo(
  ({
    className,
    source,
    analytics,
    advSub,
    affSub4,
    visibleLabel = 'Получить деньги',
    link = analytics.advertising?.monetization?.pixels?.click,
    onClick,
    ...buttonProps
  }) => {
    const router = useRouter();
    const monetizationKind = analytics.advertising?.monetization?.kind;

    // todo(MFO-1414): временно (а может и нет) скрыли фейковый лэйбл
    // const buttonLabel = getButtonLabel({ isDetailsPage });

    const href = usePixel(link, {
      sub: advSub,
      affSub4,
      type: analytics.advertising?.productType,
      source,
      pixelType: PIXELS_TYPES.CLICK,
    });

    const handleClick = (event: SyntheticEvent) => {
      event.stopPropagation();
      sendAdvertisingButtonClickEvent(analytics);

      onClick?.();

      if (!href) {
        return;
      }

      if (monetizationKind === MONETIZATION_INTERNAL) {
        router.push(href);
        return;
      }

      if (href) {
        open({
          href,
          target: '_blank',
          features: 'noopener',
        });
      }
    };

    // todo(MFO-1414): временно (а может и нет) скрыли фейковый лэйбл
    // const withFakeLabel = !isSpecial && !isDetailsPage;

    return (
      <Button className={cn(styles.button, className)} variant="primary" onClick={handleClick} {...buttonProps}>
        {visibleLabel}

        {/* <span className={styles.invisibleText}>Получить деньги</span> */}
        {/* todo(MFO-1414): временно (а может и нет) скрыли фейковый лэйбл */}
        {/* {withFakeLabel && (
                        <>

                            <span>{visibleLabel}</span>
                        </>
                    )}
                    {!withFakeLabel && } */}
      </Button>
    );
  },
);
