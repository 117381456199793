import cn from 'classnames';
import type { FC, ReactNode } from 'react';
import React, { memo } from 'react';

import { Typography } from '@sravni/react-design-system';

import styles from './CardContentItem.module.scss';

const { Text } = Typography;

export interface LightweightCardContentProps {
  label: string;
  value: ReactNode;

  boldValue?: boolean;
}

export const CardContentItem: FC<LightweightCardContentProps> = memo(({ label, value, boldValue }) => (
  <div className={styles.wrapper}>
    <Text className={styles.label}>{label}</Text>
    <Text className={cn(styles.value, { [styles.bold]: boldValue })}>{value}</Text>
  </div>
));

CardContentItem.displayName = 'CardContentItem';
