import React from 'react';

import type { ITermRange } from '@src/@types/ranges';
import { humanizeTermRange } from '@src/helpers/common';

const TERM_SPLITTER_FOR_MOBILE = ' до ';

export const getCardTerm = (term: ITermRange, isPhone: boolean) => {
  // @todo: Добавить эту штуку если данная карточка добавится на страницу с listKey === ListKeys.WL_PAGE
  //   value={isTemporaryTerm ? getTemporaryTerm(organization._id, term) : humanizeTermRange(...)}
  const termString = humanizeTermRange(term, { useAlternativeFormat: true, termWithUnit: false });

  if (!isPhone) {
    return termString;
  }

  const [firstPart, secondPart] = termString.split(TERM_SPLITTER_FOR_MOBILE);

  if (!secondPart) {
    return firstPart;
  }

  return (
    <>
      {firstPart}
      <br />
      {TERM_SPLITTER_FOR_MOBILE} {secondPart}
    </>
  );
};
