import type { FC } from 'react';
import React, { memo } from 'react';

import { Avatar, Badge } from '@sravni/react-design-system';

import { Verification } from '@src/components/icons';

import styles from './CardLogo.module.scss';

interface Props {
  logo?: string;
  isPartner?: boolean;
}

export const CardLogo: FC<Props> = memo(({ logo, isPartner }) => {
  if (isPartner) {
    return (
      <Badge placement="bottom-end" text={<Verification />} className={styles.badge} shape="circle">
        <Avatar src={logo} size={44} className={styles.logo} />
      </Badge>
    );
  }

  return <Avatar src={logo} size={44} className={styles.logo} />;
});
